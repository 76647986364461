<template>
    <div class="organization_register_profile">
        <div class="organization_register_profile__top">
            <div class="organization_register_profile__top__step">
                <span class="active">
                    ログイン情報入力
                </span>
                <span>
                    企業情報登録
                </span>
                <span>
                    登録内容確認
                </span>
                <span>
                    メール確認
                </span>
                <span>
                    ご利用登録完了
                </span>
            </div>
            <p class="organization_register_profile__top-title">
                ログイン情報入力
            </p>
        </div>
        <div class="organization_register_profile__body">
            <div class="mw-1000">
                <div class="organization_register_profile__body-message">
                    ログイン用のアカウント情報をご入力ください。
                </div>
                <div class="organization_register_profile__body__form">
                    <form
                        class="horizontal"
                        @submit="onSubmit"
                    >
                        <div class="form-ctrl">
                            <label for="email" class="text">
                                メールアドレス
                                <span class="text-active-2">（必須）</span>
                            </label>
                            <div class="form-group">
                                <Input
                                    maxlength="255"
                                    id="email"
                                    type="email"
                                    name="email"
                                    v-model="values.email"
                                    required="true"
                                    placeholder="ご入力ください"
                                />
                                <ErrorMessage
                                    v-if="checkErrorEmail(errors)"
                                    :message="errors.email"
                                >
                                </ErrorMessage>
                                <template
                                    v-if="state.errorValidateMessage.email"
                                >
                                    <ErrorMessage
                                        v-for="m of state.errorValidateMessage
                                            .email"
                                        :key="m"
                                        :message="m"
                                    >
                                    </ErrorMessage>
                                </template>
                            </div>
                        </div>
                        <div class="form-ctrl">
                            <label for="password" class="text">
                                パスワード
                                <span class="text-active-2">（必須）</span>
                            </label>
                            <div class="form-group">
                                <Input
                                    maxlength="20"
                                    id="password"
                                    type="password"
                                    name="password"
                                    v-model="values.password"
                                    required="true"
                                    placeholder="半角英数（8〜20文字）"
                                />
                                <ErrorMessage
                                    v-if="errors.password"
                                    :message="errors.password"
                                >
                                </ErrorMessage>
                            </div>
                        </div>
                        <div class="form-ctrl" v-if="!state.isComfirmState">
                            <label for="password_confirmation" class="text">
                                パスワード確認
                                <span class="text-active-2">（必須）</span>
                            </label>
                            <div class="form-group">
                                <Input
                                    maxlength="20"
                                    id="password_confirmation"
                                    type="password"
                                    name="password_confirmation"
                                    v-model="values.password_confirmation"
                                    required="true"
                                    placeholder="確認のためもう一度ご入力ください"
                                />
                                <ErrorMessage
                                    v-if="errors.password_confirmation"
                                    :message="errors.password_confirmation"
                                >
                                </ErrorMessage>
                            </div>
                        </div>
                       <div class="reCapcha-wrapper">
                            <ReCaptcha @submit="checkedCapcha()"/>
                            <ErrorMessage
                                v-if="state.errorReCapchaMessage"
                                :message="state.errorReCapchaMessage"
                            >
                            </ErrorMessage>
                        </div>
                        <AgreementsSubmit
                            :isSubmitting="isSubmitting"
                            buttonText="利用規約に同意し次へ"
                        />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/views/organization/register/profile/Profile.ts"></script>
<style lang="scss" scoped>
@import "@/presentation/views/organization/register/profile/Profile.scss";

</style>
