import { reactive, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import { Form, Field, useForm, useField } from "vee-validate";
import * as yup from "yup";
import LanguageUtil from "@/commons/LanguageUtil";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import OrganizationRegisterInteractor from "@/domain/usecases/OrganizationRegisterInteractor";
import DIContainer from "@/core/DIContainer";
import { useStore } from "vuex";
import RoutePath from "@/commons/RoutePath";
import OrganizationService from "@/services/OrganizationService";
import ReCaptcha from '@/presentation/components/re-capcha/ReCapcha.vue';
import CommonService from "@/services/CommonService";
import Constant from "@/commons/Constant";
import Input from "@/presentation/components/input/Input.vue";
import AgreementsSubmit from "@/presentation/components/agreements-submit/AgreementsSubmit.vue";
import * as agh from "agh.sprintf";
export default defineComponent({
    name: "OrganizationRegisterProfile",
    components: {
        Breadcrumb: Breadcrumb,
        ErrorMessage: ErrorMessage,
        Form: Form,
        Field: Field,
        ReCaptcha: ReCaptcha,
        Input: Input,
        AgreementsSubmit: AgreementsSubmit
    },
    setup: function () {
        var store = useStore();
        var PASSWORD_PATTERN = Constant.PASSWORD_PATTERN;
        var organizationRegisterInteractor = DIContainer.instance.get(OrganizationRegisterInteractor);
        /* eslint-disable @typescript-eslint/camelcase */
        var router = useRouter();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            listBreadcrumb: [
                {
                    route: '/', name: "TOP"
                },
                {
                    route: '', name: "基本情報入力"
                }
            ],
            checkCapcha: false,
            errorValidateMessage: {},
            errorReCapchaMessage: ""
        });
        //validate
        var _a = useForm({
            initialValues: {
                email: null,
                password: null,
                password_confirmation: null,
            }
        }), isSubmitting = _a.isSubmitting, handleSubmit = _a.handleSubmit, values = _a.values, setValues = _a.setValues, errors = _a.errors, setFieldError = _a.setFieldError;
        var email = useField("email", yup
            .string()
            .required(state.aLang.validation_required)
            .email(state.aLang.validation_format_email)
            .max(255, agh.sprintf(state.aLang.validation_max_length, 255))).value;
        var password = useField("password", yup
            .string()
            .required(state.aLang.validation_required)
            .min(8, agh.sprintf(state.aLang.validation_min_length, 8))
            .max(20, agh.sprintf(state.aLang.validation_max_length, 20))
            .matches(Constant.PASSWORD_PATTERN, state.aLang.validation_format_password)).value;
        var password_confirmation = useField("password_confirmation", function (value) {
            if (!value) {
                return state.aLang.validation_required;
            }
            if (value != values.password) {
                return state.aLang.validation_confirm_password;
            }
            return true;
        }).value;
        var onSubmit = handleSubmit(function (values) {
            var token = CommonService.verifyCallbackReCapcha();
            if (token == "" || state.checkCapcha == false) {
                state.errorReCapchaMessage = state.aLang.reCapcha_required;
                return;
            }
            state.errorValidateMessage = {};
            return organizationRegisterInteractor
                .validate(values.email, values.password, values.password_confirmation, token)
                .then(function (res) {
                store.commit("setOrganizationResgisterData", values);
                router.push(RoutePath.ORGANIZATION_REGISTER_DETAIL);
            }, function (error) {
                state.errorValidateMessage = error.validation_errors;
                setValues({
                    password: "",
                    password_confirmation: ""
                });
                setTimeout(function () {
                    setFieldError("password", "");
                    setFieldError("password_confirmation", "");
                }, 10);
            });
        });
        function goBack() {
            router.back();
        }
        onMounted(function () {
            var isConfirmPolicy = OrganizationService.checkConfirmPolicy();
            if (!isConfirmPolicy) {
                return router.push(RoutePath.LOGIN);
            }
            var organizationResgisterData = store.state.organizationResgisterData;
            if (organizationResgisterData) {
                setValues({
                    email: organizationResgisterData.email,
                    password: organizationResgisterData.password,
                    password_confirmation: organizationResgisterData.password_confirmation
                });
            }
        });
        function checkErrorEmail(error) {
            if (!error.email) {
                return false;
            }
            if (state.errorValidateMessage['email']) {
                state.errorValidateMessage['email'] = [];
            }
            return true;
        }
        function checkedCapcha() {
            state.errorReCapchaMessage = "";
            state.checkCapcha = true;
        }
        return {
            state: state,
            isSubmitting: isSubmitting,
            onSubmit: onSubmit,
            goBack: goBack,
            errors: errors,
            values: values,
            checkErrorEmail: checkErrorEmail,
            checkedCapcha: checkedCapcha
        };
    }
});
